<template>
    <div class="pl">
        <div v-if="myCookieValue == null" class="cont_auth">
            <div v-if="showMessage == false">
                <p class="cont_auth_text">Регистрация</p>
                <form class="sss" v-bind:action="urlregister" method="post">
                    <input class="sss1" type="text" id="username" name="username" placeholder="login" required>
                    <br>
                    <input class="sss2" type="password" id="password" name="password" placeholder="password" required>
                    <br>
                    <p @click="showMessage = !showMessage" class="d3">Уже есть аккаунт?</p>
                    <button class="sss4" type="submit">Войти</button>
                </form>
            </div>



            <div v-if="showMessage">
                <p class="cont_auth_text">Авторизация</p>
                <form class="sss" v-bind:action="urllogin" method="post">
                    <input class="sss1" type="text" id="username" name="username" placeholder="login" required>
                    <br>
                    <input class="sss2" type="password" id="password" name="password" placeholder="password" required>
                    <br>
                    <p class="d1">Забыли пароль?</p>
                    <p @click="showMessage = !showMessage" class="d2">Ещё не зарегистрировались?</p>
                    <button class="sss3" type="submit">Войти</button>
                </form>
            </div>
        </div>

        <div v-if="myCookieValue != null" class="cont_auth">
            <p class="nim-id">{{ message }}</p>
            <div class="lol" @click="next1()">
                <p class="lol_text">Выход</p>
            </div>
            <div class="spawn" @click="spawn">
                <p class="lol_text">/spawn</p>
            </div>
            <div class="scin">
                <p class="lol_text1">Монет: {{ mone }}$</p>
            </div>
            <div class="lop_i" @click="temp">
                <p class="lol_text2">сменить скин</p>
            </div>
            <div class="dfg" @click="vi"><p class="hyn">Голосование</p><div class="lkj-1"></div></div>
        </div>




        <div class="server_1">
            <div class="server_name1"></div>
            <p class="onlane_1" id="demo1">10/100</p>
        </div>
        <div class="server_2">
            <div class="server_name2"></div>
            <p class="onlane_1" id="demo"></p>
        </div>
    </div>
</template>


<script scoped>
const url2 = process.env.VUE_APP_URL2
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import axios from 'axios';
import Cookies from 'js-cookie';
export default {
    data() {
        return {
            message: '',
            showMessage: true,
            myCookieValue: null,
            mone: null,
            url1: process.env.VUE_APP_URL2,
            urlregister: process.env.VUE_APP_URL2 + "/register",
            urllogin: process.env.VUE_APP_URL2 + "/login"
        }
    },
    methods: {
        next1() {
            window.location.href = url2 + "/logout"
        },
        getCookieValue(key) {
            return Cookies.get(key);
        },
        Wrong_password() {
            toast("неверный логин или пароль", {
                "theme": "dark",
                "type": "error",
                "dangerouslyHTMLString": true
            })
        },
        Username_taken() {
            toast("Имя пользователя занято", {
                "theme": "dark",
                "type": "error",
                "dangerouslyHTMLString": true
            })
        },
        Wrong_name() {
            toast("никнейм должен быть минимум 3 символом", {
                "theme": "dark",
                "type": "error",
                "dangerouslyHTMLString": true
            })
        },
        Wrong_pass() {
            toast("пароль должен быть не менее 6 символов", {
                "theme": "dark",
                "type": "error",
                "dangerouslyHTMLString": true
            })
        },
        Wrong_err() {
            toast("произошла ошибка регистрации", {
                "theme": "dark",
                "type": "error",
                "dangerouslyHTMLString": true
            })
        },
        temp() {
            toast("временно недоступна", {
                "theme": "dark",
                "type": "error",
                "dangerouslyHTMLString": true
            })
        },
        spawn() {
            window.location.href = url2 + "/spawn"
        },
        vi(){

        }
        

    },
    mounted() {
        this.myCookieValue = this.getCookieValue('token');
        setInterval(() => {
            this.myCookieValue = this.getCookieValue('token');
        }, 1 * 60 * 1000);
        var myCookieValue = this.getCookieValue('token');
        var pss = this.getCookieValue('pss');
        var reg = this.getCookieValue('reg');
        if (reg == '1') {
            this.Wrong_name();
        }
        if (reg == '2') {
            this.Wrong_pass()
        }
        if (reg == '3') {
            this.Username_taken();
        }
        if (reg == '4') {
            this.Wrong_err()
        }
        if (pss) {
            this.Wrong_password();
        }
        if (myCookieValue != null) {
            axios.get(url2 + '/api/data?id=' + myCookieValue)
                .then(response => {
                    this.message = response.data.message.username;
                    this.mone = response.data.message.mone;
                })
                .catch(error => {
                    console.log(error);
                });
        }

    },
}


// fetch('https://api.minetools.eu/ping/MR.MATIX.GG/25565')
//   .then(response => response.json())
//   .then(data => {
//     var online = data.players.online + '/' + data.players.max
//     document.getElementById("demo").innerHTML = online;
//   })
//   .catch(err => console.log(err));

// fetch('https://api.minetools.eu/ping/MR.MATIX.GG/25565')
//   .then(response => response.json())
//   .then(data => {
//     var online = data.players.online + '/' + data.players.max
//     document.getElementById("demo1").innerHTML = online;
//   })
//   .catch(err => console.log(err));
</script>
<style scoped>
.hyn{
    transform: translate(6px, 4px);
    color: #FFF;
font-family: Arial;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.lkj-1{
    transform: translate(120px, -51px);
    width: 48px;
height: 48px;
flex-shrink: 0;
background: url(../assets/img/Unknown-PhotoRoom.png);
}

.dfg{
    transform: translate(50px, -155px);
    width: 158.601px;
    height: 32px;
flex-shrink: 0;
border-radius: 10px;
background: #726969;
}
.lkj{
    transform: translate(135px, -51px);
    width: 48px;
height: 48px;
flex-shrink: 0;
background: url(../assets/img/Unknown-PhotoRoom.png);
}
.lol_text2 {
    transform: translate(12px, 4px);
    color: #E1E1E1;
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.lop_i {
    transform: translate(50px, -58px);
    width: 158.601px;
    height: 32px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #726969;
}

.lol_text1 {
    transform: translate(16px, 4px);
    color: #E1E1E1;
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.scin {
    transform: translate(50px, 42px);
    width: 160.601px;
    height: 32px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #726969;
}

.spawn {
    transform: translate(30px, 142px);
    width: 84px;
    height: 33.451px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #726969;
    width: 85px;
    height: 33.451px;
    flex-shrink: 0;
}

.pl {
    position: relative;
    top: -100px;
    left: -170px;
}

.lol_text {
    transform: translate(8px, 5px);
    color: #E1E1E1;
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.lol {
    transform: translate(145px, 195.5px);
    width: 84px;
    height: 33.451px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #726969;
}

.nim-id {
    position: absolute;
    top: 1%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    color: #E0E0E0;
    font-family: Arial;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.onlane_1 {
    transform: translate(150px, -165%);
    color: #FFF;
    font-family: Arial;
    font-size: 28px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.server_name1 {
    transform: translate(30px, 15px);
    width: 80px;
    height: 48.276px;
    flex-shrink: 0;
    background: url(../assets/img/Rectangle-100.png)
}

.server_name2 {
    transform: translate(30px, 15px);
    width: 113px;
    height: 56.258px;
    flex-shrink: 0;
    background: url(../assets/img/Rectangle_102.png);
}

.server_1 {
    position: absolute;
    margin-left: 1170px;
    top: 500px;
    border-radius: 22px;
    background: url(../assets/img/Rectangle-98.png);
    width: 256px;
    height: 77px;
    flex-shrink: 0;
}

.server_2 {
    position: absolute;
    margin-left: 1170px;
    top: 600px;
    border-radius: 22px;
    background: url(../assets/img/Rectangle-99.png);
    width: 256px;
    height: 77px;
    flex-shrink: 0;
}

.d1 {
    transform: translate(50px, -40px);
    font-size: 20px;
    color: #FFF;
}

.d2 {
    transform: translate(0px, -60px);
    font-size: 20px;
    color: #FFF;
}

.d3 {
    transform: translate(30px, -40px);
    font-size: 24px;
    color: #FFF;
}

.sss1 {
    transform: translate(20px, -35px);
    font-size: 22px;
    width: 185px;
    height: 39px;
    flex-shrink: 0;
    border-radius: 12px;
}

.sss2 {
    transform: translate(20px, -25px);
    font-size: 22px;
    width: 185px;
    height: 43px;
    flex-shrink: 0;
    border-radius: 12px;
}

.sss3 {
    transform: translate(60px, -70px);
    font-size: 22px;
    width: 115px;
    height: 35.545px;
    flex-shrink: 0;
    border-radius: 12px;
}

.sss4 {
    transform: translate(60px, -50px);
    font-size: 22px;
    width: 115px;
    height: 35.545px;
    flex-shrink: 0;
    border-radius: 12px;
}

.main {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cont_hdr {
    width: 1072px;
    height: 94px;
    position: sticky;
    left: 425px;
    top: 70px;
    border-radius: 30px;
    background: rgba(24, 24, 24, 0.70);

}

.hd {
    transform: translate(20px, -2%);
    width: 478px;
    height: 80px;
    flex-shrink: 0;
    background: url('../assets/img/logo_catze_craft_end.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.hd-ii {
    transform: translate(550px, 38px);
    width: 400px;
    height: 24px;
    flex-shrink: 0;

}

.hd-i {
    margin: 8px;
    color: #FFF;
    font-family: Arial;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.hd-k {
    transform: translate(965px, -2px);
    width: 55px;
    height: 55px;
    flex-shrink: 0;
    background: url('../assets/img/korzina.png');
    background-repeat: no-repeat;
    background-size: cover;
}



.cont_auth {
    border-radius: 12px;
    position: absolute;
    top: 164px;
    margin-left: 1170px;
    width: 256px;
    height: 271px;
    flex-shrink: 0;
    background: url('../assets/img/steve4.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.cont_auth_text {
    color: rgba(232, 232, 232, 0.90);
    font-family: Arial;
    position: absolute;
    left: 55px;
    top: 10px;
    font-size: 24px;
    font-style: bold;
    font-weight: 600;
    line-height: 5px;
}

.sss {
    border-radius: 10;
    transform: translate(10px, 100px);
}</style>
