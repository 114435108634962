<template> 
  <div class="ll">
  <header_vi />

  <ProfilePl />
   <router-view></router-view>
</div>

</template>

<script>
import ProfilePl from './components/ProfilePl.vue'
import header_vi from './components/header_vi.vue'

export default {
  name: 'main_',
  components: {
  
    header_vi,
    ProfilePl
  }
}
</script>

<style>
.my-swa1 .swal2-popup {
  border-radius: 25px;
  position: absolute;
  top: 100px;
  width: 607px;
  height: 430px;
}



.ll{
  margin: 0;
  position: absolute;
  top: 0%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -0%) 
}
.mi{
  position: relative;
  top: -100px;
  left: 130px;
}
body {
  background-image: url('./assets/img/fone_launcher.png');
  
}
</style>
