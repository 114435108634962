<template>
  <div class="mi">
    <div class="cont_dj">
      <div  @click="info_dj" class="lop-u">
        <div ><p class="lop-i">i</p></div>
      </div>
      <div class="cont_text_dj">
        <div class="cont_text2_dj">
          <p class="text_dj">
            Описание: Divine Journey 2 - экспертная техно-магическая
          </p>
          <p class="text_dj">
            сборка. Здесь вам предстоит пройти через 30 уникальных глав
          </p>
          <p class="text_dj">
            с более чем 1600 квестами, которые содержат рекомендации,
          </p>
          <p class="text_dj">
            подробные описания и вознаграждения. Вас ждут более 600
          </p>
          <p class="text_dj">
            новых кастомных предметов и 5000 сбалансированных
          </p>
          <p class="text_dj">изменённых крафтов.</p>
        </div>
      </div>
    </div>



    <div class="cont_ftb">
      <div class="lop-u" @click="info_FTB" >
        <p class="lop-i">i</p>
      </div>
      <div class="cont_text_ftb">
        <div class="cont_text2_ftb">
          <p class="text_ftb">
            Описание: FTB Infinity Evolved это одна из самых популярных
          </p>
          <p class="text_ftb">
            техно-магических сборок. В ней насчитывается около 200
          </p>
          <p class="text_ftb">
            модов!. Эта сборка включает в себя разнообразные моды,
          </p>
          <p class="text_ftb">
            которые были тесно переплетены между собой крафтами и
          </p>
          <p class="text_ftb">
            другими модификациями, благодаря чему здесь соблюдается
          </p>
          <p class="text_ftb">
            идеальный баланс, который разнообразит и улучшит Ваш
          </p>
          <p class="text_ftb">геймплей на этом сервере.</p>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
   data() {
      return {
      
      }
   },
   methods: {
    info_dj(){
      this.$router.push('/shop')
    },
    info_FTB(){
      this.$router.push('/shop')
    }
   }
}
</script>

<style scoped>
.lop-u{
  width: 28px;
height: 28px;
flex-shrink: 0;
border-radius: 100px;

background: rgba(0, 0, 0, 0.62);

  transform: translate(780px, -9px);
}
.lop-i{
  transform: translate(11.4px, 0px);
  color: #FFF;
  font-family: Arial;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.lol_text {
  transform: translate(8px, 5px);
  color: #E1E1E1;
  font-family: Arial;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.lol {
  transform: translate(145px, 110px);
  width: 84px;
  height: 33.451px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #726969;
}

.nim-id {
  transform: translate(70px, 0px);
  color: #E0E0E0;
  font-family: Arial;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.onlane_1 {
  transform: translate(150px, -50px);
  color: #FFF;

  font-family: Arial;
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.server_name1 {
  transform: translate(30px, 15px);
  width: 80px;
  height: 48.276px;
  flex-shrink: 0;
  background: url(../assets/logo.png)
}

.server_name2 {
  transform: translate(30px, 15px);
  width: 80px;
  height: 48.276px;
  flex-shrink: 0;
  background: url(../assets/logo.png);
}

.server_1 {
  position: absolute;
  margin-left: 1170px;
  top: 500px;
  border-radius: 22px;
  background: url(../assets/img/FTB\ Full.png), lightgray 50% / cover no-repeat;
  width: 256px;
  height: 77px;
  flex-shrink: 0;
}

.server_2 {
  position: absolute;
  margin-left: 1170px;
  top: 600px;
  border-radius: 22px;
  background: url(../assets/img/FTB\ Full.png), lightgray 50% / cover no-repeat;
  width: 256px;
  height: 77px;
  flex-shrink: 0;
}

.d1 {
  transform: translate(50px, -40px);
  font-size: 20px;
  color: #FFF;
}

.d2 {
  transform: translate(0px, -60px);
  font-size: 20px;
  color: #FFF;
}

.d3 {
  transform: translate(30px, -40px);
  font-size: 24px;
  color: #FFF;
}

.sss1 {
  transform: translate(20px, -35px);
  font-size: 22px;
  width: 185px;
  height: 39px;
  flex-shrink: 0;
  border-radius: 12px;
}

.sss2 {
  transform: translate(20px, -25px);
  font-size: 22px;
  width: 185px;
  height: 43px;
  flex-shrink: 0;
  border-radius: 12px;
}

.sss3 {
  transform: translate(60px, -70px);
  font-size: 22px;
  width: 115px;
  height: 35.545px;
  flex-shrink: 0;
  border-radius: 12px;
}

.sss4 {
  transform: translate(60px, -50px);
  font-size: 22px;
  width: 115px;
  height: 35.545px;
  flex-shrink: 0;
  border-radius: 12px;
}


.cont_hdr {
  width: 1072px;
  height: 94px;
  position: sticky;
  left: 425px;
  top: 70px;
  border-radius: 30px;
  background: rgba(24, 24, 24, 0.70);

}

.hd {
  transform: translate(20px, -2%);
  width: 478px;
  height: 80px;
  flex-shrink: 0;
  background: url('../assets/img/logo_catze_craft_end.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.hd-ii {
  transform: translate(550px, 38px);
  width: 400px;
  height: 24px;
  flex-shrink: 0;

}

.hd-i {
  margin: 8px;
  color: #FFF;
  font-family: Arial;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.hd-k {
  transform: translate(965px, -2px);
  width: 55px;
  height: 55px;
  flex-shrink: 0;
  background: url('../assets/img/korzina.png');
  background-repeat: no-repeat;
  background-size: cover;
}



.cont_auth {
  border-radius: 12px;
  position: absolute;
  top: 164px;
  margin-left: 1170px;
  width: 256px;
  height: 271px;
  flex-shrink: 0;
  background: url('../assets/img/steve4.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.cont_auth_text {
  color: rgba(232, 232, 232, 0.90);
  font-family: Arial;
  position: absolute;
  left: 55px;
  top: 10px;
  font-size: 24px;
  font-style: bold;
  font-weight: 600;
  line-height: 5px;
}

.sss {
  border-radius: 10;
  transform: translate(10px, 100px);
}


.cont_dj {
  border-radius: 14px;
  position: absolute;
  top: 164px;

  width: 826px;
  height: 405px;
  flex-shrink: 0;
  background-image: url('../assets/img/1.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.cont_text_dj {
  width: 797px;
  height: 215px;
  position: absolute;
  left: 15px;
  top: 170px;
  border-radius: 14px;
  background: rgba(24, 24, 24, 0.70);
}

.text_dj {
  color: rgba(232, 232, 232, 0.90);
  font-family: Arial;
  font-size: 24px;
  font-style: bold;
  font-weight: 600;
  line-height: 5px;

}

.cont_text2_dj {
  width: 776px;
  height: 195px;
  position: absolute;
  left: 20px;
  top: 0;
}

.cont_ftb {
  border-radius: 14px;
  position: absolute;
  top: 611px;

  width: 826px;
  height: 440px;
  flex-shrink: 0;
  background-image: url('../assets/img/FTB Full.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.cont_text_ftb {
  width: 797px;
  height: 230px;
  position: absolute;
  left: 15px;
  top: 195px;
  border-radius: 14px;
  background: rgba(24, 24, 24, 0.70);
}

.text_ftb {
  color: rgba(232, 232, 232, 0.90);
  font-family: Arial;
  font-size: 24px;
  font-style: bold;
  font-weight: 600;
  line-height: 5px;

}

.cont_text2_ftb {
  width: 776px;
  height: 188px;
  position: absolute;
  left: 20px;
  top: 0;
}
</style>
