<template>
  <header>
    <div class="cont_hdr">
      <!-- Переделать на <router-link to="/foo">Перейти к Foo</router-link> -->
      <div class="hd" @click="home()"></div>
      <div class="hd-ii">
        <a class="hd-i" @click="shop">Донат</a>
        <a class="hd-i" @click="showAlert">Поддержка</a>
        <a class="hd-i">Гайды</a>
        <a style="text-decoration-line: underline;" @click="dl" class="hd-i">Скачать</a>
      </div>
      <div class="hd-k" @click="Basket()">
      </div>

    </div>
  </header>
</template>

<script>
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import Cookies from 'js-cookie';
export default {
  methods: {
    getCookieValue(key) {
      return Cookies.get(key);
    },
    avtor_err() {
      toast("Пожалуйста авторизуйтесь", {
        "theme": "dark",
        "type": "error",
        "dangerouslyHTMLString": true
      })
    },
    Basket() {
      var token1 = this.getCookieValue('token');
      console.log(token1);
      if (token1 == null) {
        this.avtor_err();
      } else {
        this.$router.push('/Basket')
      }
    },
    dl(){
      var token1 = this.getCookieValue('token');
      console.log(token1);
      if (token1 == null) {
        this.avtor_err();
      } else {
        window.location.href = process.env.VUE_APP_URL2 + "/Launcher/Launcher.exe"
      }
    },
    shop() {
      this.$router.push('/shop')
    },
    home() {
      this.$router.push('/')
    },
    showAlert() {
      // Use sweetalert2      
      this.$swal({ // инициализация sweetalert2
        // заголовок диалогового окна
        html: `
        <style>
          .nik1{
            transform: translate(0px, -40px);
            color: #FFF;
            font-family: Arial;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
          .nik2{
            transform: translate(0px, -60px);
            color: #FFF;
            font-family: Arial;
            font-size: 64px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
          .nik3{
            transform: translate(0px, -40px);
            color: #FFF;
            font-family: Arial;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
          .nik4{
            transform: translate(0px, -40px);
            color: #FFF;
            font-family: Arial;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-decoration-line: underline;
          }
        </style>
        <p class="nik2">Поддержка</p>
        <p class="nik1">Хотите задать вопрос, Попросить помощи или у вас есть идея для нашего проекта?!</p>
        <p class="nik3">Вы можете обратится в наш дискорд</p>
        <a class="nik4" href="https://discord.gg/BdqaQEcEyD">https://discord.gg/BdqaQEcEyD</a>`, // текст диалогового окна
        showConfirmButton: false, // скрытие кнопки подтверждения
        background: 'linear-gradient(180deg, rgba(45, 13, 243, 0.75) -7.43%, rgba(137, 41, 197, 0.75) 51.6%, rgba(181, 11, 241, 0.75) 98.07%)', // фоновый градиент
        color: '#ffffff', // цвет текста
        // ширина диалогового окна
        // отступы вокруг текста
        position: 'top', // позиция диалогового окна (в данном случае - сверху экрана)
        customClass: {
          container: 'my-swa1'
        }
      });
    },
  },
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.cont_hdr {
  width: 1072px;
  height: 94px;
  border-radius: 30px;
  background: rgba(24, 24, 24, 0.70);

}

.hd {
  transform: translate(20px, 10px);
  width: 410px;
  height: 57px;
  flex-shrink: 0;
  background: url('../assets/img/log_2.png');
  background-repeat: no-repeat;
  background-size: cover;
}

/*.hd {
  transform: translate(20px, -2%);
  width: 478px;
  height: 80px;
  flex-shrink: 0;
  background: url('../assets/img/logo_catze_craft_end.png');
  background-repeat: no-repeat;
  background-size: cover;
}*/

.hd-ii {
  transform: translate(460px, -23px);
  width: 400px;
  height: 24px;
  flex-shrink: 0;

}

.hd-i {
  margin: 16px;
  color: #FFF;
  font-family: Arial;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.hd-k {
  transform: translate(965px, -65px);
  width: 55px;
  height: 55px;
  flex-shrink: 0;
  background: url('../assets/img/korzina.png');
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
