<template>
    <div class="per">
        <p class="iol">Deluxe</p>
        <div class="ujm"></div>
        <div class="tgb"></div>
        <div class="tyb"></div>
        <div class="tob"></div>
        <p class="ddd">Полёт</p>
        <p class="ddd1">Команда /flu</p>
        <p class="fff">Телепортация на </p>
        <p class="fff1"> место смерти</p>
        <p class="fff2"> Команда /back</p>
        <div class="pppl">
            <p class="ooo">Приват чанков: 16</p>
            <p class="ooo1">загрузка чанков: 1</p>
        </div>
        <div class="pppl">
            <p class="lll">Восстановление еды</p>
            <p class="lll1">команда /hand</p>
        </div>
        <div class="rrr">
            <p class="kkk">DJ2</p>
            <div class="sss"></div>
        </div>
        <div class="rrr1">
            <p class="kkk">FTB</p>
            <div class="sss1"></div>
        </div>
        <div class="aaa" @click="vip_soh">
            <p class="aaa1">купить</p>
        </div>
    </div>
</template>



<script>
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import Cookies from 'js-cookie';
export default {
  methods: {
    getCookieValue(key) {
         return Cookies.get(key);
      },
      avtor_err() {
         toast("Пожалуйста авторизуйтесь", {
            "theme": "dark",
            "type": "error",
            "dangerouslyHTMLString": true
         })
      },
    vip_soh(){
        var token1 = this.getCookieValue('token');
         console.log(token1);
         if (token1 == null) {
            this.avtor_err();
         } else {
            window.location.href = process.env.VUE_APP_URL2 + "/deluxe"
         }
      
    }
  }
  }
</script>


<style scoped>
.pppl {
    width: 279px;
    height: 55px;
    flex-shrink: 0;
}
.aaa {
    transform: translate(180px, -420px);
    width: 279px;
    height: 69px;
    flex-shrink: 0;
    border-radius: 14px;
    background: #3F3F3F;
}

.aaa1 {
    transform: translate(80px, 10px);
    color: #FFF;
    font-family: Inter;
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.sss1 {
    transform: translate(18px, 0px);
    width: 500px;
    height: 218.54px;
    flex-shrink: 0;
    border-radius: 30px;
    background: url(../assets/img/1vip_1.png);

}

.sss {
    transform: translate(18px, 0px);
    width: 500px;
    height: 218.54px;
    flex-shrink: 0;
    border-radius: 30px;
    background: url(../assets/img/Rectangle_136.png);
}

.kkk {
    transform: translate(240px, 20px);
    color: #FFF;
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.rrr1 {
    transform: translate(53px, -420px);
    width: 537px;
    height: 281px;
    flex-shrink: 0;
    border-radius: 20px;
    background: rgba(217, 217, 217, 0.19);
}

.rrr {
    transform: translate(53px, -410px);
    width: 537px;
    height: 281px;
    flex-shrink: 0;
    border-radius: 20px;
    background: rgba(217, 217, 217, 0.19);
}

.lll1 {
    transform: translate(365px, -425px);
    color: #FFF;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.lll {
    transform: translate(365px, -410px);
    color: #FFF;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.ooo1 {
    transform: translate(365px, -460px);
    color: #FFF;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.ooo {
    transform: translate(365px, -405px);
    color: #FFF;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.fff2 {
    transform: translate(110px, -270px);
    color: #FFF;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.fff1 {
    transform: translate(110px, -256px);
    color: #FFF;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.fff {
    transform: translate(110px, -240px);
    color: #FFF;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.ddd {
    transform: translate(110px, -250px);
    color: #FFF;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.ddd1 {
    transform: translate(110px, -265px);
    color: #FFF;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.tob {
    transform: translate(294px, -180px);
    width: 60px;
    height: 60px;
    flex-shrink: 0;
    border-radius: 10px;
    background: url(../assets/img/Rectangle_2144.png);
}

.tyb {
    transform: translate(294px, -40px);
    width: 60px;
    height: 60px;
    flex-shrink: 0;
    border-radius: 10px;
    background: url(../assets/img/Rectangle_2144.png);
}

.tgb {
    transform: translate(43px, 20px);
    width: 60px;
    height: 60px;
    flex-shrink: 0;
    border-radius: 10px;
    background: url(../assets/img/Rectangle_2144.png);
}

.ujm {
    transform: translate(43px, 0px);
    width: 60px;
    height: 60px;
    flex-shrink: 0;
    border-radius: 10px;
    background: url(../assets/img/Rectangle_2144.png);
}

.iol {
    transform: translate(278px, 20px);
    color: #FFF;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.per {
    transform: translate(190px, 20px);
    width: 648px;
    height: 933px;
    flex-shrink: 0;
    border-radius: 50px;
    background: linear-gradient(180deg, rgba(66, 0, 255, 0.65) 0%, rgba(158, 0, 255, 0.65) 100%);
}</style>
