import { createApp } from 'vue'
import App from './App.vue'
import { createRouter, createWebHistory } from 'vue-router'
import shop from '@/components/shop_vi.vue'
import main from '@/components/main_vi.vue'
import basket from '@/components/Basket_vi.vue'
import Vip from '@/components/Vip_m2.vue'
import pro from '@/components/pro_m2.vue'
import prem from '@/components/prem_m2.vue'
import del from '@/components/del_m2.vue'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
// import { from } from 'core-js/core/array'

const router = createRouter({ 
    history: createWebHistory(),
    routes: [{
        name: 'magi',
        path: '/shop',
        component: shop
    },
    {
        name: 'home',
        path: '/',
        component: main
    },{
        name: 'Basket',
        path: '/Basket',
        component: basket  
    },{
        name: 'Vip-m',
        path: '/Vip',
        component: Vip
    },{
        name: 'pro-m',
        path: '/Pro',
        component: pro
    },{
        name: 'prem-m',
        path: '/Prem',
        component: prem
    },{
        name: 'del-m',
        path: '/del',
        component: del
    }]
}) 


const app = createApp(App)
app.use(VueSweetalert2);
app.use(router).mount('#App')
