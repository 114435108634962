<template>
    <div class="lop">
        <p class="lop_q">Здесь будут отображаться ваши покупки {{ message }} </p>
    </div>

    <div class="lop_w" v-if="vip_p > 0">
        <div class="lop_e">
        </div>
        <div class="lop_r">
            <p class="lop_t">привелегии</p>
            <div class="lop_y"></div>
        </div>
        <p class="lop_u">vip</p>
        <p class="lop_i">кол-во: {{ vip_p }}</p>
        <div id="app">
            <div>
            <select class="lop_o" @change="handleChange">
               <option v-for="(item, index) in items" :key="index" :value="item.url">
                 {{ item.title }}
               </option>
            </select>
           </div>
        </div>
    </div>

    <div class="lop_w" v-if="pro_p > 0">
        <div class="lop_e1">
        </div>
        <div class="lop_r">
            <p class="lop_t">привелегии</p>
            <div class="lop_y"></div>
        </div>
        <p class="lop_u">pro</p>
        <p class="lop_i">кол-во: {{ pro_p }}</p>
        <div id="app">
            <div>
            <select class="lop_o" @change="handleChange">
               <option v-for="(item, index) in items1" :key="index" :value="item.url">
                 {{ item.title }}
               </option>
            </select>
           </div>
        </div>
    </div>

    <div class="lop_w" v-if="prem_p > 0">
        <div class="lop_e2">
        </div>
        <div class="lop_r">
            <p class="lop_t">привелегии</p>
            <div class="lop_y"></div>
        </div>
        <p class="lop_u">Premium</p>
        <p class="lop_i">кол-во: {{ prem_p }}</p>
        <div id="app">
            <div>
            <select class="lop_o" @change="handleChange">
               <option v-for="(item, index) in items2" :key="index" :value="item.url">
                 {{ item.title }}
               </option>
            </select>
           </div>
        </div>
    </div>

    <div class="lop_w" v-if="del_p > 0">
        <div class="lop_e3">
        </div>
        <div class="lop_r">
            <p class="lop_t">привелегии</p>
            <div class="lop_y"></div>
        </div>
        <p class="lop_u">Deluxe</p>
        <p class="lop_i">кол-во: {{ del_p }}</p>
        <div id="app">
           <div>
            <select class="lop_o" @change="handleChange">
               <option v-for="(item, index) in items3" :key="index" :value="item.url">
                 {{ item.title }}
               </option>
            </select>
           </div>
        </div> 
    </div>

    <div class="lop_w">
        <div class="lop_e4">
        </div>
        <div class="jik">
        <div class="lop_r">
            <p class="lop_t">монеты</p>
            <div class="lop_y"></div>
        </div>
        <form class="sss" v-bind:action="urlrcon_mone" method="get">
            <input class="lop_f" type="text" id="mone" name="mone" placeholder="сумма к выдаче на сервер" pattern="[0-9]*" required>
            <!-- <input class="sss1" type="text" id="mone" name="mone" placeholder="login" required> -->
            <select class="lop_1" name="server">
                <option value="false">выбери сервер</option>
                <option value="FTB">FTB</option>
                <option value="DJ2">DJ2</option>
            </select>
            <button class="qsc" type="submit">Выдать</button>
        </form>
        </div>
    </div>

</template>


<script scoped>
const url2 = process.env.VUE_APP_URL2
import axios from 'axios';
import Cookies from 'js-cookie';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
export default {
    data() {
        return {
            items: [
                { title: 'получить', url: '/shop' },
                { title: 'FTB', url: url2 + '/rcon_vip_FTB' },
                { title: 'DJ2', url: url2 + '/rcon_vip' },
            ],
            items1: [
                { title: 'получить', url: '/shop' },
                { title: 'FTB', url: url2 + '/rcon_pro_FTB' },
                { title: 'DJ2', url: url2 + '/rcon_pro' },
            ],
            items2: [
                { title: 'получить', url: '/shop' },
                { title: 'FTB', url: url2 + '/rcon_prem_FTB' },
                { title: 'DJ2', url: url2 + '/rcon_prem' },
            ],
            items3: [
                { title: 'получить', url: '/shop' },
                { title: 'FTB', url: url2 + '/rcon_deluxe_FTB' },
                { title: 'DJ2', url: url2 + '/rcon_deluxe' },
            ],
            handleChange: function (event) {
                window.location.href = event.target.value;
            },
            
            prem_naz: 'prem',
            vip_p: 0,
            pro_p: 0,
            prem_p: 0,
            del_p: 0,
            urlrcon_mone: process.env.VUE_APP_URL2 + "/rcon_mone"
        }
    },
    methods: {
        getCookieValue(key) {
            return Cookies.get(key);
        },
        nomone(){
            toast("на вашем счастье недостаточно средств", {
                "theme": "dark",
                "type": "error",
                "dangerouslyHTMLString": true
            })
        },
        nomo(){
            toast("Выбери сервер", {
                "theme": "dark",
                "type": "error",
                "dangerouslyHTMLString": true
            }) 
        }

    },
    mounted() {
        setInterval(() => {
            var myCookieValue = this.getCookieValue('token');
        if (myCookieValue == null) {
            this.$router.push('/')
        }
        }, 1 * 60 * 1000);
        var reg = this.getCookieValue('reg');
        var token = this.getCookieValue('token');
        axios.get(url2 + '/prem/id?id=' + token)
            .then(response => {
                this.vip_p = response.data.message[0].vip;
                this.pro_p = response.data.message[0].pro;
                this.prem_p = response.data.message[0].prem;
                this.del_p = response.data.message[0].deluxe;
            })
            .catch(error => {
                console.log(error);
            });

        if (reg == '6') {
            this.nomone()
        }
        if(reg == '7'){
            this.nomo()
        }
    }
}

</script>
<style scoped>
.jik{
    transform: translate(-5px, 15px);
}
.lop_e4 {
    transform: translate(25px, 10px);
    width: 94px;
    height: 94.244px;
    flex-shrink: 0;
    border-radius: 15px;
    background: rgba(0, 0, 0, 0.50);
    background: url(../assets/img/DI102.png);
}
.lop_e3 {
    transform: translate(10px, 5px);
    width: 120px;
    height: 120px;
    flex-shrink: 0;
    border-radius: 15px;
    background: rgba(0, 0, 0, 0.50);
    background: url(../assets/img/DI1_1-2.png);
}
.lop_e2 {
    transform: translate(10px, 5px);
    width: 120px;
    height: 120px;
    flex-shrink: 0;
    border-radius: 15px;
    background: rgba(0, 0, 0, 0.50);
    background: url(../assets/img/DI1_11.png);
}
.lop_e1 {
    transform: translate(10px, 5px);
    width: 120px;
    height: 120px;
    flex-shrink: 0;
    border-radius: 15px;
    background: rgba(0, 0, 0, 0.50);
    background: url(../assets/img/12s1_1.png);
}

.lop_e {
    transform: translate(10px, 5px);
    width: 120px;
    height: 120px;
    flex-shrink: 0;
    border-radius: 15px;
    background: rgba(0, 0, 0, 0.50);
    background: url(../assets/img/DI1_1.png);
}
.qsc{
    transform: translate(125px, -70px);
    width: 148px;
    height: 46px;
    flex-shrink: 0;
    border-radius: 12px;
    background: #3F3F3F;
    color: #E9E9E9;
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    appearance: none;
}
.lop_1{
    appearance: none;
    transform: translate(0px, -60px);
    color: #FFF;
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background: rgba(73, 73, 73, 0.88);
}
.lop_f{
    color: #FFF;
    font-family: Arial;
    font-size: 20px;
    transform: translate(150px, -105px);
    width: 147px;
height: 36px;
flex-shrink: 0;
border-radius: 8px;
background: rgba(73, 73, 73, 0.88);
}
.sss{
    transform: translate(-0px, 0px);

}
.custom-select {
    width: 200px;
    height: 30px;
    
  }
.lop_p {
    transform: translate(18px, 8px);
    color: #E9E9E9;
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.lop_o {
    transform: translate(430px, -210px);
    width: 148px;
    height: 46px;
    flex-shrink: 0;
    border-radius: 12px;
    background: #3F3F3F;
    color: #E9E9E9;
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    appearance: none;
   
   
   
}

.lop_i {
    transform: translate(150px, -145px);
    color: #FFF;
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.lop_u {
    transform: translate(150px, -130px);
    width: 143px;
    height: 29px;
    flex-shrink: 0;
    color: #FFF;
    font-family: Arial;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.lop_y {
    transform: translate(72px, -22px);
    width: 15px;
    height: 15px;
    flex-shrink: 0;
    background: url(../assets/img/20894271.png);
}

.lop_t {
    transform: translate(2px, 2px);
    color: #000;
    font-family: Arial;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.lop_r {
    transform: translate(150px, -112px);
    width: 89px;
    height: 17px;
    flex-shrink: 0;
    border-radius: 4px;
    background: #ACACAC;
}

.lop_w {
    transform: translate(220px, 62px);
    width: 592px;
    height: 131px;
    flex-shrink: 0;
    border-radius: 7px 7px 9px 9px;
    background: rgba(0, 0, 0, 0.45);
}

.lop_q {
    transform: translate(65px, 15px);
    color: #FFF;
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.lop {
    transform: translate(220px, 60px);
    width: 592px;
    height: 62px;
    flex-shrink: 0;
    border-radius: 16px 16px 5px 5px;
    background: rgba(0, 0, 0, 0.60);
}
</style>
