<template>
   <div class="prem">
      <div class="pro_p" @click="pro_p()">
         <div class="pro_g">
            <div class="pro_s">
               <div class="pro_f">
                  <div class="pro_h">
                     <div class="pro_k"></div>
                     <p class="pro_l">привелегии</p>

                  </div>
                  <p class="pro_r">Pro</p>
                  <p class="pro_e">350 руб</p>
                  <div class="pro_u"></div>
               </div>
            </div>
         </div>
      </div>
      <div class="vip_p" @click="vip_p()">
         <div class="vip_g">
            <div class="vip_s">
               <div class="vip_f">
                  <div class="pro_h">
                     <div class="pro_k"></div>
                     <p class="pro_l">привелегии</p>

                  </div>
                  <p class="pro_r">Vip</p>
                  <p class="pro_e">100 руб</p>
                  <div class="pro_u"></div>
               </div>
            </div>
         </div>
      </div>
      <div class="prem_p" @click="prem_p()">
         <div class="prem_g">
            <div class="prem_s">
               <div class="prem_f">
                  <div class="pro_h">
                     <div class="pro_k"></div>
                     <p class="pro_l">привелегии</p>

                  </div>
                  <p class="pro_r">Prem</p>
                  <p class="pro_e">450 руб</p>
                  <div class="pro_u"></div>

               </div>
            </div>
         </div>
      </div>
      <div class="del_p" @click="del_p()">
         <div class="del_g">
            <div class="del_s">
               <div class="del_f">
                  <div class="pro_h">
                     <div class="pro_k"></div>
                     <p class="pro_l">привелегии</p>

                  </div>
                  <p class="pro_r">Deluxe</p>
                  <p class="pro_e">750 руб</p>
                  <div class="pro_u"></div>

               </div>
            </div>
         </div>
      </div>
      <div @click="sop">
         <div class="mon_p">
            <p class="mon_g">Пополнить майнкрафт счёт</p>
         </div>
      </div>

   </div>
</template>
  
<script>
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import Cookies from 'js-cookie';
export default {
   data() {
      return {
         showMessage: true,// не нужно потом вырежешь
      }
   },
   methods: {
      getCookieValue(key) {
         return Cookies.get(key);
      },
      avtor_err() {
         toast("Пожалуйста авторизуйтесь", {
            "theme": "dark",
            "type": "error",
            "dangerouslyHTMLString": true
         })
      },
      mon() {
         toast("на данный момент покупка монет отсутствует", {
            "theme": "dark",
            "type": "error",
            "dangerouslyHTMLString": true
         })
      },
      del_p() {
         this.$router.push('/del')
      },
      prem_p() {
         this.$router.push('/prem')
      },
      pro_p() {
         this.$router.push('/pro')
      },
      vip_p() {
         this.$router.push('/vip')
      },
      sop(){
         var token1 = this.getCookieValue('token');
         console.log(token1);
         if (token1 == null) {
            this.avtor_err();
         }else{
            this.mone()
         }
      },
      mone() {
      // Use sweetalert2      
      this.$swal({ // инициализация sweetalert2
        // заголовок диалогового окна
        html: `
        <style>
        .sss1{
         width: 445px;
height: 50px;
flex-shrink: 0;
border-radius: 14px;
background: #D9D9D9;
font-size: 22px;
        }
        .lo{
         color: #FFF;
font-family: Inter;
font-size: 32px;
font-style: normal;
font-weight: 600;
line-height: normal;
        }
        .sss3{
         width: 443px;
height: 54px;
flex-shrink: 0;
border-radius: 16px;
background: rgba(181, 181, 181, 0.78);
font-size: 22px;
        }
        </style> 
        <form class="sss" action="http://localhost:5050/mone-dl" method="get">
        <input class="sss1" type="text" id="mone" name="mone" placeholder="сумма к выдаче на сервер" pattern="[0-9]*" required>
        <p class="lo">Курс монет 1 мон = 1руб</p>
        
        <button class="sss3" type="submit">Купить</button>
    </form>`, // текст диалогового окна
        showConfirmButton: false, // скрытие кнопки подтверждения
        border: '28px',
         background: 'linear-gradient(180deg, rgba(64, 62, 62, 0.90) 0%, rgba(35, 35, 35, 0.81) 100%)',
        color: '#ffffff', // цвет текста
      });
      }
   }, mounted() {
   }
}
</script>
  
<style scoped>
.pro_u {
   transform: translate(240px, -155px);
   width: 21px;
   height: 21px;
   flex-shrink: 0;
   background: url(../assets/img/35238681.png);
}

.pro_e {
   transform: translate(15px, -15px);
   width: 84px;
   height: 26px;
   flex-shrink: 0;
   color: #FFF;
   font-family: Arial;
   font-size: 20px;
   font-style: normal;
   font-weight: 600;
   line-height: normal;
}

.pro_k {
   transform: translate(70px, 1px);
   width: 15px;
   height: 15px;
   flex-shrink: 0;
   background: url(../assets/img/20894271.png);
}

.pro_l {
   transform: translate(5px, -23px);
   color: #000;
   font-family: Arial;
   font-size: 11px;
   font-style: normal;
   font-weight: 500;
   line-height: normal;
}

.pro_h {
   transform: translate(10px, 10px);
   width: 89px;
   height: 17px;
   flex-shrink: 0;
   border-radius: 4px;
   background: #ACACAC;
}

.pro_r {
   transform: translate(15px, 0px);
   color: #FFF;
   font-family: Arial;
   font-size: 32px;
   font-style: normal;
   font-weight: 700;
   line-height: normal;
}


.mon_f {
   transform: translate(55px, -15px);
   display: inline-block;
   color: #E0E0E0;
   font-family: Arial;
   font-size: 32px;
   font-style: normal;
   font-weight: 700;
   line-height: normal;
}

.mon_s {
   transform: translate(190px, -168px);
   width: 483px;
   height: 69px;
   flex-shrink: 0;
   border-radius: 21px;
   background: #595959;
}

.mon_g {
   transform: translate(20px, 15px);
   color: #FFF;
   font-family: Arial;
   font-size: 32px;
   font-style: normal;
   font-weight: 700;
   line-height: normal;
}

.mon_p {
   transform: translate(190px, -200px);
   width: 483px;
   height: 69px;
   flex-shrink: 0;
   border-radius: 21px;
   background: #595959;
}




.del_f {
   transform: translate(145px, -10px);
   border-radius: 0px 11px 11px 0px;
   background: #3F3F3F;
   width: 276px;
   height: 136px;
   flex-shrink: 0;
}

.del_s {
   transform: translate(10px, 10px);
   width: 121px;
   height: 121.314px;
   flex-shrink: 0;
   background: url(../assets/img/DI1_1-2.png);
}

.del_g {
   border-radius: 11px 0px 0px 11px;
   background: rgba(0, 0, 0, 0.80);
   width: 149px;
   height: 136px;
   flex-shrink: 0;
}

.del_p {
   transform: translate(450px, -205px);
   width: 426px;
   height: 136px;
   flex-shrink: 0;
   border-radius: 12px;
   background: rgba(217, 217, 217, 0.32);
}




.prem_f {
   transform: translate(145px, -10px);
   border-radius: 0px 11px 11px 0px;
   background: #3F3F3F;
   width: 276px;
   height: 136px;
   flex-shrink: 0;
}

.prem_s {
   transform: translate(10px, 10px);
   width: 121px;
   height: 121.314px;
   flex-shrink: 0;
   background: url(../assets/img/DI1_11.png);
}

.prem_g {
   border-radius: 11px 0px 0px 11px;
   background: rgba(0, 0, 0, 0.80);
   width: 149px;
   height: 136px;
   flex-shrink: 0;
}

.prem_p {
   transform: translate(0px, -70px);
   width: 426px;
   height: 136px;
   flex-shrink: 0;
   border-radius: 12px;
   background: rgba(217, 217, 217, 0.32);
}




.pro_s {
   transform: translate(10px, 10px);
   width: 121px;
   height: 121.314px;
   flex-shrink: 0;
   background: url(../assets/img/12s1_1.png);
}

.pro_g {
   border-radius: 11px 0px 0px 11px;
   background: rgba(0, 0, 0, 0.80);
   width: 149px;
   height: 136px;
   flex-shrink: 0;
}

.pro_f {
   transform: translate(145px, -10px);

   border-radius: 0px 11px 11px 0px;
   background: #3F3F3F;
   width: 276px;
   height: 136px;
   flex-shrink: 0;
}

.pro_p {
   transform: translate(450px, 0px);
   width: 426px;
   height: 136px;
   flex-shrink: 0;
   border-radius: 12px;
   background: rgba(217, 217, 217, 0.32);

}




.vip_s {
   transform: translate(10px, 10px);
   width: 121px;
   height: 121.314px;
   flex-shrink: 0;
   background: url(../assets/img/DI1_1.png);
}

.vip_g {
   border-radius: 11px 0px 0px 11px;
   background: rgba(0, 0, 0, 0.80);
   width: 149px;
   height: 136px;
   flex-shrink: 0;
}

.prem {
   transform: translate(40px, 100px);
   width: 888px;
   height: 306px;
   flex-shrink: 0;
}

.vip_f {
   transform: translate(145px, -10px);

   border-radius: 0px 11px 11px 0px;
   background: #3F3F3F;
   width: 276px;
   height: 136px;
   flex-shrink: 0;
}

.vip_p {
   transform: translate(0px, -135px);
   width: 426px;
   height: 136px;
   flex-shrink: 0;
   border-radius: 12px;
   background: rgba(217, 217, 217, 0.32);
}
</style>
  
